<template>
  <v-app>
    <div style="padding: 100px">
      <v-tooltip bottom content-class="my-tooltip" dark color="grey darken-3">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">Hover me!</v-btn>
        </template>
        <span>This text should be white</span>
      </v-tooltip>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "TestTooltip",
};
</script>

<!-- Make sure this <style> block is global (remove "scoped") so it can override Vuetify's default CSS -->
<style>
/* Override both the tooltip container and its children */
.v-tooltip__content.my-tooltip,
.v-tooltip__content.my-tooltip * {
  color: #fff !important;
  background-color: #424242 !important;
}
</style>
