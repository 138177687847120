<template>
  <div class="check-expiration">
    <Header />
    <div>
      <h1>Check Annual Pass Expiration Date</h1>
      <h2>
        To view your annual pass expiration date, enter the annual pass order
        number or barcode below:
      </h2>
      <input v-model="order_number" placeholder="Order Number" />
      <input v-model="barcode" placeholder="Pass Barcode" />
      <button @click="getExpiration">Check Expiration</button>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Header from "@/components/Header";
export default {
  name: "CheckExpiration",
  // Your component logic goes here
  components: {
    Header,
  },
  data() {
    return {
      order_number: "",
      barcode: "",
      passData: {},
    };
  },
  methods: {
    // Your methods go here
    getExpiration() {
      console.log("Getting expiration date");
      console.log("ordernum: ", this.order_number);
      console.log("barcode: ", this.barcode);

      var url =
        "https://trailwaze.info/parkspass/api/getPassExpirationDate.php?";
      if (this.order_number) {
        url += `order_number=${this.order_number}`;
      } else if (this.barcode) {
        url += `barcode=${this.barcode}`;
      }

      var settings = {
        url: url,
        method: "GET",
        timeout: 0,
        headers: {
          PARKSPLUS_AUTH: "d1f713f7-0050-4c7c-ad9f-463630dc46a0",
        },
      };

      $.ajax(settings).done(function (response) {
        console.log(response);
      });
    },
  },
};
</script>

<style scoped>
.check-expiration {
  /* Your styles go here */
}
</style>
