<template>
  <div style="background-color: #fff; height: 100vh">
    <Header />
    <div id="dispFlex">
      <div id="width-cont">
        <h1>Pass Help</h1>
        <div id="box-grid">
          <div class="box-cont" @click="redirect('passhelp/stickerlanding')">
            <img src="../assets/images/Utah-sticker.svg" alt="" />
            <h2>Receive My Annual Pass Sticker</h2>
          </div>
          <div class="box-cont" @click="link()">
            <img src="../assets/images/register-icon.svg" alt="" />
            <h2>How To Register My Pass</h2>
          </div>
          <div class="box-cont" @click="redirect('plateupdate')">
            <img src="../assets/images/license-plate.svg" alt="" />
            <h2>Change License Plate Info</h2>
          </div>
          <div class="box-cont" @click="redirect('parks/PANUAL/dayuse')">
            <img src="../assets/images/shopping-cart.svg" alt="" />
            <h2>Purchase a New Annual Pass</h2>
          </div>
        </div>
      </div>
    </div>
    <div id="bottom-left">
      <h3>Talk With Us</h3>
      <div id="spacer"></div>
      <div id="side-flex">
        <img src="../assets/new_icons/call.svg" alt="" />
        <h4>888-492-1896</h4>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  name: "PassHelp",
  // Add your component logic here
  metaInfo: {
    title: "Pass Help",
    meta: [
      {
        name: "description",
        content: "Help with your Utah State Park Pass",
      },
    ],
  },
  components: {
    // Add your components here
    Header,
  },
  data() {
    return {
      // Add your data here
    };
  },
  mounted() {
    // Check if the script is already loaded
    if (!document.getElementById("ze-snippet")) {
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=51bc2338-edfd-4f18-ab25-68ba29111a5d";
      script.async = true;
      document.body.appendChild(script);
    }
  },
  beforeUnmount() {
    // Remove the script when leaving the page
    const snippet = document.getElementById("ze-snippet");
    if (snippet) snippet.remove();
  },
  methods: {
    redirect(x) {
      // Add your redirect logic here
      this.$router.push("/" + x);
    },
    link() {
      // Add your link logic here
      window.open(
        "https://parkspass.zendesk.com/hc/en-us/articles/24050131601179-Registering-Utah-State-Park-Annual-Passes"
      );
    },
    // Add your methods here
  },
};
</script>

<style scoped>
h1 {
  color: #494e53;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  margin-bottom: 30px;
}
h3 {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h4 {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#side-flex {
  display: flex;
  align-items: center;
}
#side-flex > img {
  margin-right: 5px;
}
#bottom-left {
  width: 130px;
  position: fixed;
  bottom: 25px;
  left: 25px;
}
#spacer {
  height: 1px;
  background-color: #9c9c9c;
  margin: 12px 0px;
}
#dispFlex {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
#width-cont {
  display: flex;
  width: 80%;
  max-width: 360px;
  flex-direction: column;
  align-items: flex-start;
}
.box-cont {
  border-radius: 4px;
  border: 1px solid #9c9c9c;
  background: #f5f5f5;
  margin-bottom: 20px;
  display: flex;
  padding: 8px 24px;
  align-items: center;
}
h2 {
  color: #1e252b;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-left: 30px;
}
#box-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 425px) {
  .box-cont {
    width: 48%;
    flex-direction: column;
    padding: 8px;
  }
  #box-grid {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }
  h2 {
    font-size: 16px;
    padding: 0px;
    text-align: center;
  }
  h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  #dispFlex {
    margin-top: 30px;
  }
}
</style>
