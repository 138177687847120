<template>
  <div style="background-color: #fff">
    <Header />
    <div class="side-flex">
      <img
        src="../../../assets/images/Quadfishathon-Logo.png"
        alt=""
        class="fifty grey-box"
        style="padding: 30px 50px"
      />
      <div class="fifty grey-box">
        <h1>2025 Quadfishathon</h1>
        <h2>28 hours of ice fishing!</h2>
        <v-divider class="divider"></v-divider>
        <div id="products">
          <div class="button-cont" v-if="rockPast != true">
            <button
              v-if="quad == false"
              class="inactive"
              @click="selected('quad')"
            >
              The Quad (All 4 State Parks)
            </button>
            <button v-else class="active" @click="selected('quad')">
              The Quad (All 4 State Parks)
            </button>
          </div>

          <!-- <button
            v-if="lostCreek == false"
            class="inactive"
            @click="selected('lostCreek')"
          >
            Lost Creek State Park
          </button>
          <button v-else class="active" @click="selected('lostCreek')">
            Lost Creek State Park
          </button> -->
          <div class="button-cont" v-if="eastPast != true">
            <button
              v-if="eastCanyon == false"
              class="inactive"
              @click="selected('eastCanyon')"
            >
              East Canyon State Park
            </button>
            <button v-else class="active" @click="selected('eastCanyon')">
              East Canyon State Park
            </button>
          </div>
          <div class="button-cont" v-if="rockPast != true">
            <button
              v-if="rockport == false"
              class="inactive"
              @click="selected('rockport')"
            >
              Rockport State Park
            </button>
            <button v-else class="active" @click="selected('rockport')">
              Rockport State Park
            </button>
          </div>
          <div class="button-cont" v-if="echoPast != true">
            <button
              v-if="echo == false"
              class="inactive"
              @click="selected('echo')"
            >
              Echo State Park
            </button>
            <button v-else class="active" @click="selected('echo')">
              Echo State Park
            </button>
          </div>
        </div>
        <h3 style="text-align: left">Price: ${{ price }}</h3>
        <div id="quant">
          <h3>Participants</h3>
          <div id="increment">
            <img
              class="icon"
              src="../../../assets/images/remove.png"
              @click="remove()"
              alt=""
            />
            <h3 id="quant-spacing">{{ quantity }}</h3>
            <img
              class="icon"
              src="../../../assets/images/add.png"
              @click="add()"
              alt=""
            />
          </div>
        </div>
        <button class="green-button" @click="Continue()">Continue</button>
      </div>
    </div>
    <div class="side-flex">
      <div class="fifty grey-box centered">
        <h4>4 park Fishing Tournament</h4>
        <img src="../../../assets/images/fisher.png" alt="" />
        <ol>
          <li>Lost Creek State Park</li>
          <li>East Canyon State Park</li>
          <li>Rockport State Park</li>
          <li>Echo State Park</li>
        </ol>
        <div class="stroke"></div>
        <img src="../../../assets/images/dancing.png" alt="" />
        <h3>
          Win up to <span class="bigText">$3900</span> by participating in the
          Quad
        </h3>
        <div class="stroke"></div>
        <h2>Registration Fees</h2>
        <div style="width: 100%; margin-top: 20px">
          <p>$50/each park</p>
          <p>$150/the quad (all 4 parks)</p>
          <p>You must pre-register</p>
        </div>
        <h2 style="margin-top: 20px">Tournament Dates & Time</h2>
        <div style="width: 100%; margin-top: 20px">
          <p class="lineWithSep">
            <span class="bolder">Jan 18th </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            Lost Creek State Park
          </p>
          <p class="lineWithSep">
            <span class="bolder">Feb 1st </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            Rockport State Park
          </p>
          <p class="lineWithSep">
            <span class="bolder">Feb 2nd </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            Echo State Park
          </p>
          <p class="lineWithSep">
            <span class="bolder">Feb 15th </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            East Canyon State Park
          </p>
          <p style="margin-top: 10px">
            Each tournament starts at 7:00 AM and ends @ 1:00 PM.
          </p>
        </div>
        <h2 style="margin-top: 20px">Registration Due Dates</h2>
        <div style="width: 100%; margin-top: 20px">
          <p class="lineWithSep">
            <span class="bolder">Jan 16th </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            Lost Creek Registration Ends
          </p>
          <p class="lineWithSep">
            <span class="bolder">Jan 30th </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            Rockport Registration Ends
          </p>
          <p class="lineWithSep">
            <span class="bolder">Jan 31st </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            Echo Registration Ends
          </p>
          <p class="lineWithSep">
            <span class="bolder">Feb 13th </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            East Canyon Registration Ends
          </p>
        </div>
        <div class="stroke"></div>
        <h2>Prize Drawing</h2>
        <p>
          There will be a prize drawing after each contest. $2,500 in prizes
          available at each park, must be present to win.
        </p>
        <div style="width: 100%; margin-top: 20px">
          <h3>Individual Park Prizes</h3>
          <p class="lineWithSep">
            <span class="bolder2">1st Place </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            $600
          </p>
          <p class="lineWithSep">
            <span class="bolder2">2nd Place </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            $400
          </p>
          <p class="lineWithSep">
            <span class="bolder2">3rd Place </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            $250
          </p>
        </div>
        <div style="width: 100%; margin-top: 20px">
          <h3>Quad Prizes</h3>
          <p class="lineWithSep">
            <span class="bolder2">1st Place </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            $1500
          </p>
          <p class="lineWithSep">
            <span class="bolder2">2nd Place </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            $1000
          </p>
          <p class="lineWithSep">
            <span class="bolder2">3rd Place </span
            ><span class="seperator"
              ><img src="../../../assets/images/sepgreen.svg" alt=""
            /></span>
            $700
          </p>
        </div>
        <div class="stroke"></div>
        <h2 style="margin-top: 20px">Rules</h2>
        <div style="width: 100%; margin-top: 20px" class="rules">
          <p>
            <span class="bolder3"
              >Mandatory check in is required at each contest. Check-in will be
              open an hour (6 am) before the contest begins.</span
            >
          </p>
          <p>
            <span class="bolder3">Fish Species: </span>Trout and Kokanee Salmon
            tournament only.
          </p>
          <p>
            <span class="bolder3">No Cheating: </span>Law Enforcement will be
            observing the tournament. If you are caught cheating you will be
            expelled from the tournament.
          </p>
          <p>
            <span class="bolder3">Pre-Registation Required: </span>You must
            pre-register for the event before fishing.
          </p>
          <p>
            <span class="bolder3">OHVs: </span>Allowed but they must use the
            launch ramp for entry and exit from the lake.
          </p>
          <p>
            <span class="bolder3">Possession Limit: </span>Four fish. Each
            participant can measure up to 4 fish during the contest. Any fish
            measured will be considered toward the participant's legal fish
            limit. At the Lost Creek contest live well bags will be supplied to
            all contestants so fish caught within the slot limit, 15 inches to
            22 inches, can still be measured and released.
          </p>
          <p>
            <span class="bolder3">Awards: </span>Given to first, second, and
            third-place participants with the largest measured trout.
            (tiebreaker will be the largest fish measured first.)
          </p>
          <p>
            <span class="bolder3">Fishing Guidebook: </span>All rules of the
            fishing guidebook will be followed. You must have a valid Utah
            fishing license.
          </p>
          <p>
            <span class="bolder3">Sportsmanship: </span>Courtesy, sportsmanship,
            and conservation must be displayed at all times.
          </p>
          <p>
            <span class="bolder3">Party Fishing or Group Fishing: </span>Not
            allowed. You may not fish near someone who is not signed up for the
            tournament.
          </p>
          <p>
            <span class="bolder3">Tents: </span>Will be allowed but you can not
            set up a tent until 7 AM of each tournament event.
          </p>
          <p>
            <span class="bolder3">No Coolers: </span>No coolers will be allowed
            on the ice.
          </p>
          <p>
            <span class="bolder3">Injured Fish: </span>If any of the fish are
            injured they must be kept as part of your limit (except at Lost
            Creek).
          </p>
          <p>
            <span class="bolder3">Fish Length: </span>You may measure up to 4
            fish at each park. Your longest fish measured will be the fish on
            record for the individual park tournament. If you participate in the
            Quadfishathon, your longest fish measured at each park will go
            towards your combined length total for the overall tournament. A fin
            will be clipped. Park/Wildlife staff will measure the fish and
            record the time at the official measure station. Their decision will
            be final and can be not disputed.
          </p>
          <p>
            <span class="bolder3">Time: </span>The tournament starts at 7:00 AM
            and ends at 1:00 PM. After checking in, tournament anglers will wait
            in the staging area with all of their gear. At 7:00 am tournament
            anglers will be allowed to venture out on the ice and a location of
            their choosing. No tournament anglers will be allowed to drill holes
            until 7:00 AM. Fish can be checked anytime during the tournament
            hours. Tournament anglers must be in line at 1:00 pm. If tournament
            anglers are not in line at 1:00 pm their fish will not be measured.
          </p>
          <p>
            <span class="bolder3">Results: </span>Recorded as the fish are
            entered.
          </p>
          <p>
            <span class="bolder3">Testing: </span>State Parks reserve the right
            to test the top three fish to verify the time caught. Fish must be
            freshly caught.
          </p>
          <p>
            <span class="bolder3">Prize Drawing: </span>One ticket will be given
            to each tournament angler at check-in and one additional ticket will
            be given for each fish submitted for a total of up to five tickets
            in the prize drawing. Tickets are free and additional tickets cannot
            be purchased.
          </p>
          <p>
            <span class="bolder3">Disqualification: </span>Any cheating or
            violation of rules will result in disqualification.
          </p>
          <p><span class="bolder3">REFUNDS: </span>No refunds or transfers.</p>
        </div>
        <h2 style="margin-top: 20px">Contact Information</h2>
        <img
          src="../../../assets/images/contact.png"
          alt=""
          style="width: 70%; margin-top: 20px"
        />
        <p style="text-align: center">
          For questions, contact support@parkspassutah.com
        </p>
      </div>
      <div class="fifty"></div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  name: "QuadInfo",
  data() {
    return {
      quad: false,
      lostCreek: false,
      eastCanyon: false,
      rockport: false,
      echo: false,
      price: 0,
      quantity: 1,
      eastPast: false,
    };
  },
  created() {
    // if todays date is past feburary 13th then east past is true
    const today = new Date();
    const feb13 = new Date("2025-02-14");
    const jan30 = new Date("2025-01-31");
    const jan31 = new Date("2025-02-01");
    if (today > feb13) {
      this.eastPast = true;
    }
    if (today > jan30) {
      this.rockPast = true;
    }
    if (today > jan31) {
      this.echoPast = true;
    }
  },
  methods: {
    selected(park) {
      console.log(park);
      if (park == "quad") {
        if (!this.quad) {
          this.quad = true;
          this.lostCreek = false;
          this.eastCanyon = false;
          this.rockport = false;
          this.echo = false;
        }
      } else {
        this.quad = false;
        if (park == "lostCreek") {
          this.lostCreek = !this.lostCreek;
        } else if (park == "eastCanyon") {
          this.eastCanyon = !this.eastCanyon;
        } else if (park == "rockport") {
          this.rockport = !this.rockport;
        } else if (park == "echo") {
          this.echo = !this.echo;
        }
      }
      this.calculatePrice();
    },
    add() {
      this.quantity++;
      this.calculatePrice();
    },
    remove() {
      if (this.quantity > 1) {
        this.quantity--;
      }
      this.calculatePrice();
    },
    calculatePrice() {
      // if quad is selected then its 150 a person
      // quantity is the person count
      // all the other parks are 50 a person
      // you either have the quad selected or you can have on or more of the other parks selected

      if (this.quad) {
        this.price = this.quantity * 150;
      } else {
        const selectedParks = [
          this.lostCreek,
          this.eastCanyon,
          this.rockport,
          this.echo,
        ];
        const numSelectedParks = selectedParks.filter((park) => park).length;
        console.log(numSelectedParks);

        this.price = this.quantity * 50 * numSelectedParks;
      }
    },
    Continue() {
      const selectedParks = [];
      if (this.quad) {
        selectedParks.push("Quad");
      }
      if (this.lostCreek) {
        selectedParks.push("Lost Creek");
      }
      if (this.eastCanyon) {
        selectedParks.push("East Canyon");
      }
      if (this.rockport) {
        selectedParks.push("Rockport");
      }
      if (this.echo) {
        selectedParks.push("Echo");
      }

      localStorage.setItem("selectedParks", JSON.stringify(selectedParks));
      this.$router.push(`/parks/quadfishathon/waivers/${this.quantity}`);
    },
  },
  components: {
    Header,
  },
  // Add your component options here
};
</script>

<style scoped>
.side-flex {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  align-items: flex-start;
}
.fifty {
  width: 45%;
}
.grey-box {
  border-radius: 4px;
  border: 1px solid #808080;
  background: #f5f7f7;
  padding: 30px 20px;
}
h1 {
  color: #494e53;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}
h2 {
  color: #494e53;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.divider {
  margin: 20px 0px;
  stroke-width: 1px;
  stroke: #808080;
}
.inactive {
  border-radius: 4px;
  border: 1px solid #808080;
  color: #808080;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 15px 18px;
  margin-right: 9px;
  margin-bottom: 18px;
}
.active {
  border-radius: 4px;
  border: 3px solid #96a145;
  color: #96a145;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 15px 18px;
  margin-right: 9px;
  margin-bottom: 18px;
}
h3 {
  color: #494e53;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h4 {
  color: #494e53;
  text-align: center;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#increment {
  border-radius: 4px;
  border: 1px solid #494e53;
  color: #494e53;
  display: flex;
  width: 162px;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 15px;
  height: 48px;
}
#quant-spacing {
  padding: 0px 20px;
  border-left: 1px solid #494e53;
  border-right: 1px solid #494e53;
  color: #494e53;
  height: 48px;
  display: flex;
  align-items: center;
}
.icon {
  text-align: center;
}
#quant {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}
li {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
ol {
  margin-top: 20px;
}
.stroke {
  background-color: #494e53;
  margin: 20px 0px;
  width: 100%;
  height: 1px;
}
.bigText {
  color: #494e53;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.lineWithSep {
  display: flex;
  align-items: center;
}
.seperator {
  margin: 0px 10px;
}
.bolder {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  width: 64px;
}
.bolder2 {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  width: 73px;
}
.bolder3 {
  color: #494e53;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.rules > p {
  margin: 10px 0px;
}
.green-button {
  border-radius: 48px;
  border: 1px solid #96a145;
  background: #96a145;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 8px 27px;
  margin-top: 25px;
}
@media (max-width: 769px) {
  .side-flex {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .fifty {
    width: 95%;
    margin-bottom: 20px;
  }
  .side-flex > img {
    margin-bottom: 40px;
  }
}
@media (max-width: 426px) {
  h1 {
    color: #494e53;
    text-align: center;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  h2 {
    color: #494e53;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  h4 {
    color: #494e53;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  h3 {
    text-align: center;
  }
  .bolder {
    width: 77px;
    line-height: unset;
  }
  .bolder2 {
    line-height: unset;
  }
  .lineWithSep {
    align-items: flex-start;
    margin: 5px 0px;
  }
}
</style>
