<template>
  <div style="background-color: white; min-height: 100vh">
    <Header />
    <div style="position: relative; color: white; font-family: 'mediumRoboto'">
      <img
        src="../../assets/images/mirabilite-tours-header.png"
        class="eventImg"
      />
      <!-- <div class="bottom-left">
          <p class="eventSmall whiteText">January 1st 2023 at 10 am</p>
          <h3 class="eventLarge whiteText">First Day Hike</h3>
          <p class="eventSub whiteText">Information and Tickets</p>
        </div> -->
    </div>
    <div class="bubble-cont">
      <div class="grey-bubble">
        <p class="eventSmall">2025</p>
        <h3 class="eventLarge">Mirabilite Tours</h3>
        <p class="eventSub">Information and Registration</p>
      </div>
    </div>

    <div class="page-wrap">
      <v-breadcrumbs
        divider="/"
        :items="breadcrumbs"
        class="breadcrumbs"
      ></v-breadcrumbs>
      <p class="dateTitle">Select a Date</p>
      <div data-app class="inputBox">
        <v-select :items="items" v-model="value" label="Dates" solo></v-select>
      </div>
      <div v-if="value != null" @click="route()" class="green-btn">
        Continue
      </div>

      <v-divider class="divider" />
      <p class="passTitle">Event Information</p>
      <div class="passContainer2">
        <div class="infoMargin">
          <div class="passContainer">
            <img src="../../assets/images/info.svg" />
            <div class="iconMargin">
              <div class="eventTitle">Overview</div>
              <p>
                Are you interested in visiting the Great Salt Lake State Park to
                see the Mirabilite Formations? Now is your chance to attend a
                Ranger lead tour about these formations. These salt mineral
                deposits can only be seen during specific winter conditions and
                we do not know how long these formations will last this year.
                This is a unique opportunity to walk along Silver Sands Beach
                with a Park Ranger to view and speak about the rare Mirabilite
                Formations that form during winter.
              </p>
              <br />
              <p>
                The walk will begin on the back patio of the Visitors Center.
                This event is $3 per person + the
                <router-link to="/parks/UTGREA/dayuse" class="greenLinkText"
                  >$10 (per vehicle) entry fee</router-link
                >. Dogs are welcome but must be on leash. This walk is expected
                to take about 45 minutes. The walk will be just under a mile on
                the beach, expect typical beach hazards during the walk.
                Waterproof Shoes required to get to Mirabilites.
              </p>
              <br />
              <p>
                If you would like to buy your day use fee ahead of time you can
                do so here:
                <router-link to="/parks/UTGREA/dayuse" class="greenLinkText URL"
                  >https://parkspass.utah.gov/parks/UTGREA/dayuse</router-link
                >
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../assets/images/personCheck.svg" />
            <div class="iconMargin">
              <div class="eventTitle">Tickets Available</div>
              <p>
                Everyone must pre-register, we will only have 30 people max
                capacity for each time slot. If you are not pre-registered we
                can not ensure there will be room on the tour.
              </p>
            </div>
          </div>
        </div>
        <div class="infoMargin2">
          <div class="passContainer">
            <img src="../../assets/images/location.svg" />
            <div class="iconMargin">
              <div class="eventTitle">Location</div>
              <p>
                Meet on the back deck of the Visitors Center at the Great Salt
                Lake State Park (Exit 104 off of I-80). This event is not on
                Antelope Island.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../assets/images/lantern.svg" />
            <div class="iconMargin">
              <div class="eventTitle">What to bring</div>
              <p>
                Waterproof Shoes are highly recommended for this tour,
                conditions are usually wet this time of year. Dress accordingly
                for the weather. We may even encounter some ice on the beach.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../assets/images/Disclaimer.svg" />
            <div class="iconMargin">
              <div class="eventTitle">Disclaimer</div>
              <p>
                *This event may be canceled if the weather is snowy and blocks
                visibility of the formations.
              </p>
              <p>
                **This is event is held at the Great Salt Lake Marina in Magna,
                and NOT on Antelope Island.
              </p>
            </div>
          </div>
          <div class="passContainer">
            <img src="../../assets/images/phone.svg" />
            <div class="iconMargin">
              <div class="eventTitle">Event Contact Info</div>
              <p>
                For more information about this event, contact the Great Salt
                Lake State Park Visitor Center at 801-828-0787.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <script src="https://sdks.shopifycdn.com/js-buy-sdk/v2/latest/index.umd.min.js"></script>
<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://evey-files.s3.amazonaws.com/js/evey_buy_button.js"></script> -->

<script>
import Header from "../../components/Header.vue";
//import $ from "jquery";
export default {
  name: "DiscGolf",
  metaInfo: {
    title: "Mirabilite Tours",
    meta: [
      {
        name: "description",
        content: "Register for a Mirabilite Tour at The Great Salt Lake.",
      },
    ],
  },
  components: {
    Header,
  },
  data() {
    return {
      items: [
        "January 11, 2025",
        "January 12, 2025",
        "January 18, 2025",
        "January 19, 2025",
        "January 20, 2025",
      ],
      value: null,
      collectionId: "415819202782",
      breadcrumbs: [],
    };
  },
  created() {
    this.breadcrumbs = [
      { text: "Home", disabled: false, href: "/" },
      {
        text: "The Great Salt Lake",
        disabled: false,
        href: "/parks/UTGREA/dayuse",
      },
      { text: "Event", disabled: true },
    ];

    // if todays date is past the date in this.items i want to remove it from the items list
    for (var i in this.items) {
      var currentDate = new Date(this.items[i]);
      currentDate.setDate(currentDate.getDate() + 1);
      if (new Date() > currentDate) {
        this.items.shift();
      }
    }
  },
  mounted() {
    this.loadShopifyCollection();
    document.getElementById("header").scrollIntoView();
  },
  methods: {
    dayUse() {
      this.$router.push("/parks/UTGREA/dayuse");
    },
    route() {
      var fullDate = this.value.substring(0, 10);
      var date = fullDate.split(" ");
      var month = date[0].trim();
      var day = date[1].trim();
      var passDate = month + day;
      this.$router.push(`/parks/UTGREA/mirabilite/${passDate}`);

      //   if (this.value == "Saturday, January 14") {
      //     this.$router.push("/parks/UTGREA/mirabilite/jan14");
      //   } else if (this.value == "Sunday, January 15") {
      //     this.$router.push("/parks/UTGREA/mirabilite/jan15");
      //   } else if (this.value == "Monday, January 16") {
      //     this.$router.push("/parks/UTGREA/mirabilite/jan16");
      //   } else if (this.value == "Saturday, January 21") {
      //     this.$router.push("/parks/UTGREA/mirabilite/jan21");
      //   } else if (this.value == "Sunday, January 22") {
      //     this.$router.push("/parks/UTGREA/mirabilite/jan22");
      //   }
    },
    // loadShopifyCollection: function () {
    //   if (window.Evey) {
    //     if (window.Evey.buy_button) {
    //       $(document).ready(() => {
    //         this.EveyShopifyBuyInit();
    //       });
    //     } else {
    //       this.loadScript();
    //     }
    //   } else {
    //     this.loadScript();
    //   }
    // },
    loadScript: function () {
      var scriptURL =
        "https://evey-files.s3.amazonaws.com/js/evey_buy_button.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = this.EveyShopifyBuyInit;
    },
    stopLoader: function () {
      document.getElementById("loader").style.visibility = "hidden";
    },
    // EveyShopifyBuyInit: function () {
    //   // this.stopLoader();
    //   Evey.buy_button.buildShopifyBuyButton({
    //     shop: "parkstoreutah.myshopify.com",
    //     collectionId: this.collectionId,
    //     elementId: "collection-component-1616176464282",
    //     storefrontAccessToken: "1f4659f29b2004e36d6e8ebd35640005",
    //     collectLicensePlate: false,
    //     licensePlateRequired: false,
    //     options: {
    //       product: {
    //         styles: {
    //           product: {
    //             "@media (min-width: 601px)": {
    //               "max-width": "300px",
    //               "margin-left": "20px",
    //               "margin-bottom": "50px",
    //               width: "calc(25% - 20px)",
    //             },
    //             img: {
    //               height: "calc(100% - 15px)",
    //               position: "absolute",
    //               left: "0",
    //               right: "0",
    //               top: "0",
    //             },
    //             imgWrapper: {
    //               "padding-top": "calc(75% + 15px)",
    //               position: "relative",
    //               height: "0",
    //             },
    //           },
    //           button: {
    //             ":hover": {
    //               "background-color": "#87913e",
    //             },
    //             "background-color": "#96a145",
    //             ":focus": {
    //               "background-color": "#87913e",
    //             },
    //             "border-radius": "40px",
    //           },
    //         },
    //         text: {
    //           button: "Add to cart",
    //         },
    //       },
    //       productSet: {
    //         styles: {
    //           products: {
    //             "@media (min-width: 601px)": {
    //               "margin-left": "-20px",
    //             },
    //           },
    //         },
    //       },
    //       modalProduct: {
    //         contents: {
    //           img: false,
    //           imgWithCarousel: true,
    //           button: false,
    //           buttonWithQuantity: true,
    //         },
    //         styles: {
    //           product: {
    //             "@media (min-width: 601px)": {
    //               "max-width": "100%",
    //               "margin-left": "0px",
    //               "margin-bottom": "0px",
    //             },
    //           },
    //           button: {
    //             ":hover": {
    //               "background-color": "#87913e",
    //             },
    //             "background-color": "#96a145",
    //             ":focus": {
    //               "background-color": "#87913e",
    //             },
    //             "border-radius": "40px",
    //           },
    //         },
    //         text: {
    //           button: "Add to cart",
    //         },
    //       },
    //       option: {},
    //       cart: {
    //         styles: {
    //           button: {
    //             ":hover": {
    //               "background-color": "#87913e",
    //             },
    //             "background-color": "#96a145",
    //             ":focus": {
    //               "background-color": "#87913e",
    //             },
    //             "border-radius": "40px",
    //           },
    //         },
    //         text: {
    //           total: "Subtotal",
    //           button: "Checkout",
    //         },
    //       },
    //       toggle: {
    //         styles: {
    //           toggle: {
    //             "background-color": "#96a145",
    //             ":hover": {
    //               "background-color": "#87913e",
    //             },
    //             ":focus": {
    //               "background-color": "#87913e",
    //             },
    //           },
    //         },
    //       },
    //     },
    //     calendarCss: `
    //             .evey-calendar-container td.selected {
    //               background-color: #96A145 !important;
    //             }
    //             .evey-calendar-container td.selected .markers span {
    //               color: #96A145 !important;
    //             }
    //             .evey-calendar-container td.current-day {
    //               background-color: #FFFFFF !important;
    //             }
    //             .evey-calendar-container td.current-day .cell-date {
    //               color: #9aa656 !important;
    //             }
    //             .evey-calendar-container .available-events .selected {
    //               background-color: #96A145 !important;
    //             }
    //             .evey-calendar-container .modal-footer button {
    //               color: #96A145;
    //             }
    //             .evey-calendar-container:hover .modal-footer button {
    //               color: #838c41;
    //               cursor: pointer;
    //             }
    //           `,
    //     triggerCss: `
    //             button[data-evey-trigger="scheduler"] {
    //               border: 1px solid #c2c2c2;
    //               padding: 10px 30px 10px 7px;
    //               width:100%;
    //               text-align:left;
    //               font-size:inherit;
    //               display:flex;
    //               align-items:center;
    //               position: relative;
    //             }
    //             button[data-evey-trigger="scheduler"]:after {
    //               content: url("data:image/svg+xml; utf8, %3Csvg%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M17.5%202H15V1a1%201%200%2010-2%200v1H6V1a1%201%200%2000-2%200v1p.5C1.7%202%201%202.7%201%203.5v15c0%20.8.7%201.5%201.5%201.5h15c.8%200%201.5-.7%201.5-1.5v-15c0-.8-.7-1.5-1.5-1.5zM3%2018h14V8H3v10z%22%20fill%3D%22%23849235%22%2F%3E%3C%2Fsvg%3E");
    //               display:inline-block;
    //               position:absolute;
    //               right:10px;
    //               padding-left: 16px;
    //               width:20px;
    //               height:20px;
    //             }
    //           `,
    //   });
    // },
  },
};
</script>

<style scoped>
.grey-bubble {
  border-radius: 64px;
  background: #e5eef6;
  margin: 0px 40px;
  padding: 30px 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1vw;
}

.bubble-cont {
  display: flex;
  position: relative;
  top: -80px;
  margin-bottom: -80px;
}

.green-text {
  color: #96a145;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}

.v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}

p {
  line-height: 22px;
}

.shopifyCollectionWrap {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.divider {
  margin: 24px 0px;
}

.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}

.dateTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  /* margin-top: 44px; */
}

.passTitle {
  font-size: 24px;
  font-family: "boldRoboto";
  margin-bottom: 12px;
  margin-top: 44px;
}

.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}

.greenLinkText {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  cursor: pointer;
}
.eventImg {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: middle;
  height: auto;
  position: relative;
}

.golfImage {
  width: 330px;
  height: 198px;
  object-fit: cover;
  margin-top: 25px;
  margin-right: 25px;
  object-position: 0% 0;
}

.cloud {
  margin-right: 25px;
}

.bottom-left {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 1600px;
  padding: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}

.eventText {
  margin: 0px;
}

.eventTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-family: "boldRoboto";
  font-size: 18px;
}

.shopifyContainer {
  position: relative;
  width: 100%;
  /* background-color: whitesmoke; */
  display: flex;
}

.shopifyWrap,
.shopifyWrap2 {
  display: inline-block;
  height: 100%;
  /* background-color: whitesmoke; */
  border: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flexWrapRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.shopifyFlex {
  display: flex;
  /* background-color: whitesmoke */
}

.shopifyBox,
.shopifyBox2,
.waiverBox {
  background-color: white;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 32px;
  position: relative;
  margin-left: 0px;
}

.waiverBox {
  margin-left: 32px;
}

.shopifyBox {
  margin: 0px;
  margin-bottom: 32px;
}

.shopifyBox2 {
  margin-top: 0px;
}

.shopifyBox2 a {
  font-size: 24px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
}

.shopify-buy__product {
  padding-top: 50px;
  max-width: calc(75% - 20px) !important;
  width: calc(75% - 20px) !important;
}

.collectionDiv {
  padding: 32px 0px;
  height: 100%;
}

.eventBox p,
.eventBox li,
.eventBox li a,
.eventBox a {
  font-size: 16px;
}

.green-btn {
  height: 42px;
  line-height: 42px;
  border-radius: 32px;
  background-color: #96a145;
  color: white;
  width: 140px;
  padding: 0px 16px;
  text-align: center;
  font-size: 16px;
  font-family: "regularRoboto";
  margin-top: 8px;
  cursor: pointer;
}

.gray-btn {
  padding: 16px;
  border-radius: 32px;
  background-color: gray;
  color: white;
  width: 200px;
  text-align: center;
  font-size: 18px;
  margin-top: 16px;
  cursor: auto;
}

.green-btn:hover {
  background-color: #818a3c;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 12px;
  margin-top: 32px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid lightgray;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #96a145;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.waiverBox {
  text-align: left;
  max-width: 30%;
  align-self: baseline;
}

b {
  font-weight: bold;
}

.mobileShow {
  display: none;
}

.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}

.passType {
  font-size: 24px;
  font-family: "boldRoboto";
  color: #494e53;
  margin-top: 0px;
  padding: 4px 0px;
}

.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}

.passContainer {
  display: flex;
  margin-bottom: 8px;
  align-items: flex-start;
}

.passContainer2 {
  display: flex;
  margin: 12px 0px;
}

.buttonContainer {
  float: right;
  display: block;
  margin: 12px 16px;
  cursor: pointer;
  height: 24px;
  margin-left: auto;
}

#signUpButton {
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  font-weight: 1000;
}

.iconMargin {
  margin-left: 14px;
}

.passMargin {
  margin-left: 34px;
}

.infoMargin {
  max-width: 50%;
}

.infoMargin2 {
  margin-left: 34px;
  max-width: 50%;
}

.price {
  text-align: center;
  display: block;
  margin: 3px;
}

.month {
  font-size: 12px;
  color: #96a145;
  text-align: center;
  line-height: 24px;
  font-family: "boldRoboto";
  /* font-weight: 1000; */
}

.day {
  font-size: 18px;
  color: #494e53;
  text-align: center;
  line-height: 24px;
  /* font-family: 'boldRoboto'; */
  font-weight: 1000;
}

a {
  text-decoration: none;
  color: #96a145;
}

.passContainer > img {
  margin-top: 15px;
}

.v-breadcrumbs {
  padding: 18px 0px;
}

.inputBox {
  width: 281px;
}

@media only screen and (max-width: 850px) {
  .passTitle {
    margin-top: 20px;
  }

  .passContainer2 {
    display: inline;
  }

  .passContainer {
    align-items: flex-start;
  }

  .passContainer > img {
    margin-top: 15px;
  }

  .passMargin {
    margin-left: 16px;
  }

  .infoMargin {
    max-width: 100%;
    margin-left: 0px;
  }

  .infoMargin2 {
    margin-left: 0px;
    max-width: unset;
  }

  .bottom-left {
    padding: 25px;
  }

  .page-wrap {
    padding: 16px;
  }

  .mobileShow {
    display: block;
  }

  .waiverBox {
    display: none;
  }

  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }

  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }

  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }

  .flexWrap {
    width: auto;
  }

  .shopifyFlex {
    display: block;
  }

  .waiverBox {
    max-width: 100%;
    margin: 8px;
  }

  .shopifyWrap2 {
    margin-left: 0px;
  }

  .wrapLeft {
    margin: 8px;
  }

  .collectionDiv {
    padding: 0px;
  }

  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }

  .shopifyBox2 {
    margin-top: 8px;
  }

  .passText,
  .passType {
    font-size: 16px;
  }

  .eventInfoTitle {
    font-size: 16px;
  }

  .infoBox {
    margin-top: 8px !important;
  }

  .eventBox p,
  .eventBox li,
  .eventBox li a,
  .eventBox a {
    font-size: 14px;
  }

  .eventInfoTitle {
    font-size: 22px;
    font-family: "boldRoboto";
  }

  .eventImg {
    /* filter: brightness(80%); */
    object-position: top;
    height: 350px;
    object-fit: cover;
  }

  .inputBox {
    width: unset;
  }
}

@media only screen and (max-width: 425px) {
  .grey-bubble {
    margin: 0px;
    border-radius: 0px;
    padding: 20px;
    display: unset;
  }
  .iconMargin {
    width: 300px;
  }
}
@media only screen and (max-width: 375px) {
  .URL {
    font-size: 12px;
  }
}
@media only screen and (max-width: 320px) {
  .iconMargin {
    width: 250px;
  }
  .URL {
    font-size: 10px;
  }
}
</style>
