export const golfJson = [
  {
    name: "Green River State Park",
    abbr: "UTGREE",
    image: "green.png",
    site: "https://stateparks.utah.gov/parks/green-river/",
    teetime: "https://stateparks.utah.gov/golf/green-river/reserve/",
    plate: true,
    meta: "Purchase a day-use pass to Green River State Park and come relax under the cottonwood trees or play a round of 9 holes on the banks of the Green River.",
    description:
      "The Green River State Park is located in Green River, Utah. The Green River Starts in Wyoming and flows nearly 300 miles to reach Utah, where it runs another nearly 400 miles and eventually leads into Lake Powell.\n\nGreen River State Park is a great spot to start river trips through Labyrinth, and Stillwater canyons. There is plenty to do. The park features a challenging 9 hole golf course, great spots for camping, including cabins, boating, rafting/floating, hiking, ATV, mountain biking, and more! There are many species of birds here and the river is home to many different species of fish. Green River is also home to the annual Green River Melon Days Festival in September.",
    location: "Green River",
    type: "Day-Use Pass",
    restrooms: true,
    camping: true,
    dumpStation: true,
    dogsAllowed: true,
    hiking: true,
    swimming: true,
    boating: true,
    fishing: true,
    kayaking: true,
    wifi: false,
    wheelChair: true,
    picnic: true,
    firepits: true,
    museum: false,
    biking: false,
    offHighway: true,
    golf: true,
  },
  {
    name: "Palisade State Park",
    abbr: "UTPALI",
    image: "palisade.png",
    site: "https://stateparks.utah.gov/parks/palisade/",
    teetime: "https://stateparks.utah.gov/golf/palisade/teetime/",
    plate: true,
    meta: "Buy an online pass for Palisade State Park. Come play 18-holes, go camping, go swimming in the reservoir, or bring your non-motorized or electric boat.",
    description:
      "Palisade State Park is located just outside of Sterling, Utah.\n\nThe park is open year-round with no holiday closures. The golf course is open during later spring, summer, and early fall, weather permitting.\nSummer hours - 8:00 am to 10:00 pm\nWinter hours - 8:00 am to 5:00 pm\n\nThe average temperature of 76.5 degrees Fahrenheit, 1.03 inches of rain, and 0.2 inches of snowfall. \n\nThings to do in Palisade\nTent, RV, cabin, and yurt camping. Fishing, swimming, nonmotorized or electric boating. OHV trails are also accessible from the park. \n\nGas-powered boats are not allowed on the reservoir\n\nPalisade rentals are available:\nPaddle boats and Paddleboards: $15 for 1 hour, $30 for 4 hours, and $50 for all-day\nCanoes and Adult Kayaks: $10 for 1 hour, $20 for 4 hours, and $30 for all-day\nKid Kayaks: $5 for 1 hour and $15 for 4 hours\nFire Pits: $10 per night (Limited supply- on a first-come-first-serve basis)\n\nPets are allowed if on a maximum 6-foot leash\n\nWhich types of fish are in Palisade Reservoir?\nRainbow Trout, Tiger Trout, and some Cutthroat Trout.\n\nHiking Trails in Palisade are located just around the west side of the reservoir and go over the dam to the campground loop.",
    location: "Sterling",
    type: "Day-Use Pass",
    restrooms: true,
    camping: true,
    dumpStation: true,
    dogsAllowed: true,
    hiking: true,
    swimming: true,
    boating: true,
    fishing: true,
    kayaking: true,
    wifi: false,
    wheelChair: true,
    picnic: true,
    firepits: true,
    museum: false,
    biking: false,
    offHighway: true,
    golf: true,
  },
  {
    name: "Wasatch Mountain State Park",
    abbr: "UTWASA",
    image: "wasatch.png",
    site: "https://stateparks.utah.gov/parks/wasatch-mountain/",
    teetime: "https://stateparks.utah.gov/parks/wasatch-mountain/golf-courses/",
    plate: true,
    meta: "Buy a day-use pass to Wasatch Mountain State Park. Come visit any time of year. Enjoy the popular golf courses or spend time in the mountains hiking and much more.",
    description:
      "Things to do in Wasatch Mountain State Park\nThere are things to do year-round. Camp in the Pine Creek Campground, reserve a tee time at one of the four popular 18-hole golf courses or hike, bike, ATV, or horseback ride on miles of mountain trails. In winter months, ski, snowshoe, or snowmobile through the alpine terrain.\n\nRentals available\nWasatch Excursions offers both guided and solo ATV, UTV, and Snowmobile rentals. With access to the pristine 22,000 acres of Wasatch Mountain State Park, Wasatch Excursions offers a unique service filled with thrills and spectacular views. They also offer FREE SHUTTLES to and from Park City and the Heber Valley.",

    location: "Midway",
    type: "Day-Use Pass",
    restrooms: true,
    camping: true,
    dumpStation: true,
    dogsAllowed: true,
    hiking: true,
    swimming: false,
    boating: false,
    fishing: true,
    kayaking: false,
    wifi: false,
    wheelChair: true,
    picnic: true,
    firepits: true,
    museum: false,
    biking: true,
    offHighway: true,
    golf: true,
  },
];
