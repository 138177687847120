import { render, staticRenderFns } from "./Mirabilite2023.vue?vue&type=template&id=0ef48bea&scoped=true&"
import script from "./Mirabilite2023.vue?vue&type=script&lang=js&"
export * from "./Mirabilite2023.vue?vue&type=script&lang=js&"
import style0 from "./Mirabilite2023.vue?vue&type=style&index=0&id=0ef48bea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef48bea",
  null
  
)

export default component.exports